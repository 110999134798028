const HOST_URL:string = process.env.REACT_APP_HOST_URL as string;

const GRAMMAR_CHECK:string = process.env.REACT_APP_GRAMMAR_CREATE_ORIGIN_CHANGE_TEXTS?process.env.REACT_APP_GRAMMAR_CREATE_ORIGIN_CHANGE_TEXTS:'';
const GRAMMAR_COMPARE:string = process.env.REACT_APP_GRAMMAR_COMPARE_TEXTS?process.env.REACT_APP_GRAMMAR_COMPARE_TEXTS:'';
const CHATBOT_API_URL:string = process.env.REACT_APP_CHATBOT_API_URL?process.env.REACT_APP_CHATBOT_API_URL:'';
const LEVEL_AND_TEXTBOOK_SPEAKING:string = process.env.REACT_APP_LEVEL_AND_TEXTBOOK_SPEAKING?process.env.REACT_APP_LEVEL_AND_TEXTBOOK_SPEAKING:'';
const LEVEL_AND_TEXTBOOK_WRITING:string = process.env.REACT_APP_LEVEL_AND_TEXTBOOK_WRITING?process.env.REACT_APP_LEVEL_AND_TEXTBOOK_WRITING:'';

const ACTIVITY_MANAGEMENT_SPEAKING_IDEA_EXCHANGE:string = process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_IDEA_EXCHANGE?process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_IDEA_EXCHANGE:'';
const ACTIVITY_MANAGEMENT_SPEAKING_STORY_VLOG:string = process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_STORY_VLOG?process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_STORY_VLOG:'';
const ACTIVITY_MANAGEMENT_SPEAKING_ROLE_PLAY:string = process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_ROLE_PLAY?process.env.REACT_APP_ACTIVITY_MANAGEMENT_SPEAKING_ROLE_PLAY:'';

const ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING:string= process.env.REACT_APP_ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING?process.env.REACT_APP_ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING:'';
const ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING_UPDATE:string=process.env.REACT_APP_ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING_UPDATE?process.env.REACT_APP_ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING_UPDATE:'';

const LEARNING_MANAGEMENT_WRITING_SPARK_WRITING_FILTER_DATA:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_WRITING_FILTER_DATA?process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_WRITING_FILTER_DATA:'';
const LEARNING_MANAGEMENT_SPARK_WRITING_GET_ALL_CAMPUS_DATA: string = process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_GET_ALL_CAMPUS?process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_GET_ALL_CAMPUS:''
const LEARNING_MANAGEMENT_SPARK_WRITING_GET_LEVELS_OF_CAMPUS_DATA: string = process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_GET_LEVELS_OF_CAMPUS?process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_GET_LEVELS_OF_CAMPUS:''
const LEARNING_MANAGEMENT_WRITING_SPARK_GET_STUDENT:string = process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_STUDENT
? process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_STUDENT : '';
const LEARNING_MANAGEMENT_WRITING_SPARK_GET_DRAFT_BY_STUDENT:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_DRAFT_BY_STUDENT? process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_DRAFT_BY_STUDENT:'';
const LEARNING_MANAGEMENT_WRITING_SPARK_GET_ADVISOR:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_ADVISOR?process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_ADVISOR:'';

const LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_TEMPORARY_SAVE:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_TEMPORARY_SAVE? process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_TEMPORARY_SAVE:'';
const LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_SUBMIT:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_SUBMIT?process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_SUBMIT:'';

const LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_BY_STUDENT:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_BY_STUDENT? process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_BY_STUDENT:'';
const LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_OVERALL_BY_STUDENT:string=process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_OVERALL_BY_STUDENT? process.env.REACT_APP_LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_OVERALL_BY_STUDENT:'';
const LEARNING_RESULT_MANAGEMENT_WRITING_SPARK_WRITING_GET_REPORTS:string=process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_WRITING_SPARK_WRITING_GET_REPORTS? process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_WRITING_SPARK_WRITING_GET_REPORTS:'';
const LEARNING_RESULT_MANAGEMENT_SPEAKING_FILTER_DATA: string = process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_FILTER_DATA?process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_FILTER_DATA:''
const LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_ALL_CAMPUS_DATA: string = process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_ALL_CAMPUS?process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_ALL_CAMPUS:''
const LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_LEVELS_OF_CAMPUS_DATA: string = process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_LEVELS_OF_CAMPUS?process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_LEVELS_OF_CAMPUS:''
const LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_STUDENT: string = process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_STUDENT?process.env.REACT_APP_LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_STUDENT:''

const IS_DEV:string = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_IS_DEV : '';
const IS_DEV_CHECK:string = process.env.REACT_APP_IS_DEV_CHECK ? process.env.REACT_APP_IS_DEV_CHECK:'';
const IS_HEAD_COMP:string = process.env.REACT_APP_IS_HEAD_COMP ? process.env.REACT_APP_IS_HEAD_COMP:'';
export const CONFIG = {
    BASEURL: HOST_URL,
    IS_DEV:IS_DEV,
    IS_DEV_CHECK:IS_DEV_CHECK,
    HEADCHECKVALUE:IS_HEAD_COMP,
    GRAMMAR: {
        CHECK:HOST_URL+GRAMMAR_CHECK,
        COMPARE:HOST_URL+GRAMMAR_COMPARE
    },
    CHATBOT: {
        URL:HOST_URL+CHATBOT_API_URL
    },
    LEVEL_AND_TEXTBOOK: {
        SPEAKING:{
            GET: {
                DATA: HOST_URL+LEVEL_AND_TEXTBOOK_SPEAKING
            }
        },
        WRITING: {
            GET: {
                DATA: HOST_URL+LEVEL_AND_TEXTBOOK_WRITING
            }
        }
    },
    ACTIVITY_MANAGEMENT: {
        SPEAKING: {
            GET: {
                DATA: {
                    IDEA_EXCHANGE: HOST_URL+ACTIVITY_MANAGEMENT_SPEAKING_IDEA_EXCHANGE,
                    STORY_VLOG: HOST_URL+ACTIVITY_MANAGEMENT_SPEAKING_STORY_VLOG,
                    ROLE_PLAY: HOST_URL+ACTIVITY_MANAGEMENT_SPEAKING_ROLE_PLAY
                },
            }
        },
        WRITING: {
            SPARK_WRITING: {
                GET: {
                    DATA: HOST_URL+ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING,
                },
                SET: {
                    OUTLINE: HOST_URL+ACTIVITY_MANAGEMENT_WRITING_SPARK_WRITING_UPDATE
                }
            }
        }
    },
    LEARNING_MANAGEMENT: {
        WRITING: {
            SPARK_WRITING: {
                GET: {
                    FILTER_DATA: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_WRITING_FILTER_DATA,
                    ALL_CAMPUS: HOST_URL+LEARNING_MANAGEMENT_SPARK_WRITING_GET_ALL_CAMPUS_DATA,
                    LEVELS_OF_CAMPUS: HOST_URL+LEARNING_MANAGEMENT_SPARK_WRITING_GET_LEVELS_OF_CAMPUS_DATA,
                    FIND_STUDENTS: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_GET_STUDENT,
                    FIND_DRAFT: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_GET_DRAFT_BY_STUDENT,
                    WRITING_ADVISOR: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_GET_ADVISOR,
                    REPORT_BY_STUDENT: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_BY_STUDENT,
                    REPORT_OVERALL_BY_STUDENT: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_GET_REPORT_OVERALL_BY_STUDENT,
                },
                POST: {
                    FEEDBACK_TEMPORARY_SAVE: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_TEMPORARY_SAVE,
                    FEEDBACK_SUBMIT: HOST_URL+LEARNING_MANAGEMENT_WRITING_SPARK_FEEDBACK_SUBMIT
                }
            }
        }
    },
    LEARNING_RESULT_MANAGEMENT: {
        WRITING: {
            SPARK_WRITING: {
                GET: {
                    ALL_REPORTS: HOST_URL+LEARNING_RESULT_MANAGEMENT_WRITING_SPARK_WRITING_GET_REPORTS,
                }
            }
        },
        SPEAKING: {
            GET: {
                DATA: {
                    ALL_CAMPUS: HOST_URL+LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_ALL_CAMPUS_DATA,
                    LEVELS_OF_CAMPUS: HOST_URL+LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_LEVELS_OF_CAMPUS_DATA,
                    FILTER_DATA: HOST_URL+LEARNING_RESULT_MANAGEMENT_SPEAKING_FILTER_DATA,
                    FIND_STUDENTS: HOST_URL+LEARNING_RESULT_MANAGEMENT_SPEAKING_GET_STUDENT,
                }
            }
        }
    }
}